.ant-btn.time-button.ant-btn-link:hover {
    color: #40a9ff;
    background-color: #fff;
    border-color: #40a9ff;
    border-style: solid;
}

.select-time-box {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 5px;
    color: #40a9ff;
    background-color: #fff;
    border-color: #40a9ff;
    border-style: solid;
    border-width: 1px;
    border-radius: 2px;
}