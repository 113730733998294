@media (max-width: 575px) {
    .ant-steps-item-tail, .ant-steps-item-container > .ant-steps-item-icon {
        display: none !important;
    }
    
    .paymentWrapper {
        margin-top: 5px;
    }

    .bodyWrapper .ant-card-body {
        padding-left: 12px;
        padding-right: 12px;
    }

    .ant-steps-item-title {
        padding-right: 0px !important;
    }

    #register_name_first, #register_name_middle, #register_address_city, #register_address_state {
        margin-bottom: 10px;
    }

    #doctor-image {
        display: none;
    }
}

@media (min-width: 576px) {
    .ant-steps-item-tail, .ant-steps-item-container > .ant-steps-item-icon {
        display: block !important;
    }

    .ant-steps-item-content > .ant-steps-item-title > span > .ant-steps-item-icon {
        display: none !important;
    }

    .ant-row .paymentWrapper > div:first-child {
        order: 1;
    }
    
    #register_name_first, #register_name_middle, #register_address_city, #register_address_state {
        width: 30%
    }

    #register_name_last, #register_address_zip {
        width: 40%
    }

    #payment > .ant-form-item > .ant-form-item-row > .ant-form-item-control {
        margin: auto;
    }
}

.ant-steps-item-title {
    width: 100%;
}

.success {
    font-size: 30px;
    font-weight: 500;
    color: #1890ff;
}
